import { Switch, Route, Redirect,useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Landing from "pages/Landing";
import LandingUs from "pages/LandingUs";
import ModoCheco from "pages/ModoCheco";

// Font Awesome Style Sheet
import "@fortawesome/fontawesome-free/css/all.min.css";

// Tailwind CSS Style Sheet
import "assets/styles/tailwind.css";
import "assets/styles/custom.css";

/* import Other from 'pages/Other'; */
import Desk from "pages/Desk";
import Blog from "pages/Blog";
import PromotionsPage from "pages/PromotionsPage";
import VigoSeguro from "pages/VigoSeguro";
import Termns from "pages/Terminos";
import Aviso from "pages/Aviso";
import Semovi from "pages/Semovi";
import BlogInfo from "pages/BlogInfo";
import MedicoSeguro from "pages/MedicoSeguro";
import PreguntasFrecuentes from "pages/PreguntasFrecuentes";
import Modal from "components/modal/Modal";
import Pasarela from "pages/Pasarela";
import Promo from "./assets/img/landingRedisign/CS-Septiembre(1440x600).webp";
import Renovaciones from "components/renovaciones/Renovaciones";
import PromoMobile from "./assets/img/landingRedisign/main-promotion-mobile.png";
import CochePromo from "./assets/img/landingRedisign/CS-Mani.webp";
import GMMPromo from "./assets/img/landingRedisign/GMM-Mani.webp";
import CochePromoMobile from "./assets/img/landingRedisign/CS-Mani-Mobile.webp";
import GMMPromoMobile from "./assets/img/landingRedisign/GMM-Mani-Mobile.webp";
import CocheSept25 from "./assets/img/landingRedisign/CS-Septiembre25.webp";
import CocheMobileSept25 from "./assets/img/landingRedisign/CSMobile-Septiembre25.webp";
import CocheGPMexico from "./assets/img/landingRedisign/Checo-GPMexico(1140x475).webp";
import CocheMobileGPMexico from "./assets/img/landingRedisign/Checo-GPMexico(360x600).webp";
import BannerTR1S from "./assets/img/landingRedisign/Banner_TR1S_1440x600.png";
import BannerMobileTR1S from "./assets/img/landingRedisign/Banner_TR1S_360x600.png";
import CocheGPBrasil from "./assets/img/landingRedisign/CS-RaceWeek-Brasil(1440x600).webp";
import CocheMobileGPBrasil from "./assets/img/landingRedisign/CS-RaceWeek-Brasil(360x600).webp";
import CocheGPLasVegas from "./assets/img/landingRedisign/CS-RaceWeek-Brasil(1440x600).webp";
import CocheMobileGPLasVegas from "./assets/img/landingRedisign/CS-RaceWeek-Brasil(360x600).webp";
import BuenFin23Desk from "./assets/img/landingRedisign/BuenFin23(1440x600).webp";
import BuenFin23Mobile from "./assets/img/landingRedisign/BuenFin23(360x600).webp";
import Navidad23Desk from "./assets/img/landingRedisign/Navidad(1140x475).webp";
import Navidad23Mobile from "./assets/img/landingRedisign/Navidad(360x600).webp";
import EneroInter24Desk from "./assets/img/landingRedisign/Enero-Inter2024-(1440x600).webp";
import EneroInter24Mobile from "./assets/img/landingRedisign/Enero-Inter2024-(360x600).webp";
import INTERFEB24Desk from "./assets/img/landingRedisign/Febrero2024(1140x600).webp";
import INTERFEB24Mobile from "./assets/img/landingRedisign/Febrero2024(360x600).webp";
import RaceweekBareinDesk from "./assets/img/landingRedisign/RW-Barein(1140x600).webp";
import RaceweekBareinMobile from "./assets/img/landingRedisign/RW-Barein(360x600).webp";
import RaceweekArabiaSDesk from "./assets/img/landingRedisign/RW-ArabiaSaudita(1140x600).webp";
import RaceweekArabiaSMobile from "./assets/img/landingRedisign/RW-ArabiaSaudita(360x600).webp";
import { AuthProvider } from "components/context/Auth";
import Cobranza from "pages/Cobranza";

function App() {
  const [showModal, setShowModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [currentBanners, setCurrentBanners] = useState([]);
  const location = useLocation();
  const currentPage = location.pathname;

  // propiedades de Category, name, discount son para etiquetas de GTM,
  // Category categoria o temporalidad del evento/banner
  // Name nombre para identificar el creativo/diseño de la imagen
  // promotion la promocion que se esta dando
  //fechaInicio en formato (mes-día-año)
  //fechaFin en formato (mes-día-año)
  const isDesktop = width > 500;
  const imagesData = [
    {
      image: isDesktop ? RaceweekArabiaSDesk : RaceweekArabiaSMobile,
      link: "coche",
      category: "RACEWEEKARABIA",
      name: "SEGUROGANAS",
      promotion: "750_Amazon",
      fechaInicio: "03-04-2024", 
      fechaFin: "03-09-2024",
    },
    {
      image: isDesktop ? RaceweekBareinDesk : RaceweekBareinMobile,
      link: "coche",
      category: "RACEWEEKBAREIN",
      name: "SEGUROGANAS",
      promotion: "500_Amazon",
      fechaInicio: "02-26-2024", 
      fechaFin: "03-02-2024",
    },
    {
      image: isDesktop ? INTERFEB24Desk : INTERFEB24Mobile,
      link: "coche",
      category: "INTERFEB",
      name: "INTERFEB",
      promotion: "600_Amazon",
      fechaInicio: "02-01-2024", 
      fechaFin: "02-11-2024",
    },
    {
      image: isDesktop ? EneroInter24Desk : EneroInter24Mobile,
      link: "coche",
      category: "INTER2024",
      name: "INTER2024",
      promotion: "500_Amazon",
      fechaInicio: "01-01-2024", 
      fechaFin: "01-31-2024",
    },
    {
      image: isDesktop ? Navidad23Desk : Navidad23Mobile,
      link: "coche",
      category: "FIESTAS23",
      name: "FIESTAS23",
      promotion: "700_Amazon",
      fechaInicio: "12-05-2023",
      fechaFin: "12-31-2023",
    },
    {
      image: isDesktop ? BuenFin23Desk : BuenFin23Mobile,
      link: "coche",
      category: "BUENFIN23",
      name: "BUENFIN23",
      promotion: "1000_Amazon",
      fechaInicio: "11-15-2023",
      fechaFin: "11-20-2023",
    },
    {
      image: isDesktop ? CocheGPLasVegas : CocheMobileGPLasVegas,
      link: "coche",
      category: "INTERCHECO",
      name: "CS-GPLasVegas",
      promotion: "500_Amazon",
      fechaInicio: "11-13-2023",
      fechaFin: "11-14-2023",
    },
    {
      image: isDesktop ? BannerTR1S : BannerMobileTR1S,
      link: "tr1s",
      category: "CelularProtect",
      name: "CelularProtect",
      promotion: "teregalamosunseguro",
      fechaInicio: "10-24-2023",
      fechaFin: "11-14-2023",
    },
    {
      image: isDesktop ? CocheGPBrasil : CocheMobileGPBrasil,
      link: "coche",
      category: "INTERCHECHO",
      name: "CS-GPBrasil",
      promotion: "500_Amazon",
      fechaInicio: "10-30-2023",
      fechaFin: "11-05-2023",
    },
    {
      image: isDesktop ? CocheGPMexico : CocheMobileGPMexico,
      link: "coche",
      category: "INTERCHECHO",
      name: "CS-GPMexico",
      promotion: "1000_Amazon",
      fechaInicio: "10-02-2023",
      fechaFin: "10-29-2023",
    },
    {
      image: isDesktop ? CocheGPMexico : CocheMobileGPMexico,
      link: "coche",
      category: "INTERCHECHO",
      name: "CS-GPMexico",
      promotion: "1000_Amazon",
      fechaInicio: "10-02-2023",
      fechaFin: "10-29-2023",
    },
    {
      image: isDesktop ? Promo : PromoMobile,
      link: "coche",
      category: "VIVA2023",
      name: "CS-Septiembre-Patria",
      promotion: "500_Amazon",
      fechaInicio: "09-21-2023",
      fechaFin: "09-24-2023",
    },
    {
      image: isDesktop ? CochePromo : CochePromoMobile,
      link: "coche",
      category: "VERANO2023",
      name: "CS-Mani",
      promotion: "500_Amazon",
      fechaInicio: "09-24-2023",
      fechaFin: "09-24-2023",
    },
    {
      image: isDesktop ? GMMPromo : GMMPromoMobile,
      link: "gmm",
      category: "VERANO2023",
      name: "GMM-Mani",
      promotion: "500_Amazon",
      fechaInicio: "09-18-2023",
      fechaFin: "09-22-2023",
    },
    {
      image: isDesktop ? CocheSept25 : CocheMobileSept25,
      link: "coche",
      category: "VIVA23",
      name: "CS-Mani",
      promotion: "500_Amazon",
      fechaInicio: "09-25-2023",
      fechaFin: "09-30-2023",
    }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    // Función para actualizar el estado cuando cambia el tamaño de la ventana
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    // Agregar un listener para el evento de cambio de tamaño de ventana
    window.addEventListener("resize", handleResize);
    // Limpiar el listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const closeBanner = async () => {
    try {
      sessionStorage.setItem("closeBanner", JSON.stringify({ closeBanner: 1 }));
    } catch (e) {
      console.log("Error set LocalStorage::", e);
    }
    //consult closeBanner counter:
    try {
      let responseCounter = await sessionStorage.getItem("countCloseBanner");
      if (responseCounter) {
        let counterdata = JSON.parse(sessionStorage.getItem("countCloseBanner"));
        counterdata.times = counterdata.times + 1;
        try {
          sessionStorage.setItem("countCloseBanner", JSON.stringify({ times:counterdata.times}));
        } catch (e) {
          console.log("Error set LocalStorage::", e);
        }
      }else{
        try {
          sessionStorage.setItem("countCloseBanner", JSON.stringify({ times: 1 }));
        } catch (e) {
          console.log("Error set LocalStorage::", e);
        }
      }
    } catch (e) {
      console.log("Error get LocalStorage::", e);
    }

   
  };

 /*  const getSatus = async () => {
    try {
      let responseLocal = await sessionStorage.getItem("closeBanner");
      responseLocal = JSON.parse(responseLocal);
      if (responseLocal) {
        setShowModal(false);
      }
    } catch (e) {
      console.log("Error get LocalStorage::", e);
    }
  }; */

  useEffect(() => {
    let today = new Date();
    let currentMonth = today.getMonth() + 1;
    let fToday =
      /* today.getFullYear() + "-" + currentMonth + "-" + today.getDate(); */
        currentMonth + "-" + today.getDate()+ "-" +today.getFullYear();
    let currentDay = new Date(fToday);
    imagesData.map((data,index) => {
      let fechaI = new Date(data.fechaInicio);
      let fechaF = new Date(data.fechaFin);
      if (currentDay >= fechaI && currentDay <= fechaF) {
        currentBanners.push(data);
      }
    });

    if (currentBanners.length) {
      setShowModal(true);
    }
    setCurrentBanners(currentBanners);
    //getSatus();
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
    closeBanner();
  };

  return (
    <>
    <AuthProvider>
      {(showModal && currentPage === "/") &&  (
        <Modal images={currentBanners} onClose={handleCloseModal} />
      )}
      <Switch>
        <Route exact path="/us" component={LandingUs} />
        <Route exact path="/" component={Landing} />
        <Route exact path="/renovacion/pasarela" component={Pasarela} />
        <Route exact path="/modocheco" component={ModoCheco} />
        {/* <Route exact path="/other" component={Other} /> */}
        <Route exact path="/about-us" component={Desk} />
        <Route exact path="/blog" component={Blog} />
        <Route exact path="/blog/:name" component={Blog} />
        <Route exact path="/semovi" component={Semovi} />
        <Route exact path="/aviso-de-privacidad" component={Aviso} />
        <Route exact path="/promotions" component={PromotionsPage} />
        <Route exact path="/viaje-seguro" component={VigoSeguro} />
        <Route exact path="/medico-seguro" component={MedicoSeguro} />
        <Route exact path="/terminos-condiciones" component={Termns} />
        <Route exact path="/renovacion/success" component={Renovaciones} />
        <Route exact path="/pay/:id_liga" component={Cobranza} />
        <Route
          exact
          path="/preguntas-frecuentes"
          component={PreguntasFrecuentes}
        />
        <Route exact path="/info/:page" component={BlogInfo} />
        <Redirect from="*" to="/" />
      </Switch>
    </AuthProvider>
      
    </>
  );
}

export default App;
