import { ReciboInfoType } from './CobranzaEnum';
import styles from './ReciboInfo.module.css';
import { ContactPhone } from "./CobranzaEnum";
import calendar_img from '../../assets/img/cobranza/calendar_expired.png';
import info_img from '../../assets/img/cobranza/info.png';
import ok_img from '../../assets/img/cobranza/position_ok.png';
import policy_expired from '../../assets/img/cobranza/policy_expired.png';
import clock from '../../assets/img/cobranza/clock.png';

export default function ReciboInfo({ type, title, message, isActionDisabled, onClickAction }) {
  const contact_phone = ContactPhone;

  const WhatsLink = () => {
    return (
      <a href={`https://wa.me/${contact_phone.replace(/\s+/g, '')}`} className={styles['pago-recibido-info-telefono']}>
        {contact_phone}
      </a>
    )
  }

  const ImageTypeInfo = () => {
    if (type === ReciboInfoType.NO_ENCONTRADA || 
        type === ReciboInfoType.DESHABILITADA || 
        type === ReciboInfoType.INFO ||
        type === ReciboInfoType.INACTIVIDAD ||
        type === ReciboInfoType.ERROR_LIGA_REINTENTO
      ) {
      return <img src={info_img} className={styles['img-info']} alt="" />
    } else if (type === ReciboInfoType.EXPIRO_LIGA) {
      return <img src={calendar_img} className={styles['img-info']} alt="" />
    } else if (type === ReciboInfoType.YA_PAGADA) {
      return <img src={ok_img} className={styles['img-info']} alt="" />
    } else if (type === ReciboInfoType.MAS_TARDE) {
      return <img src={clock} className={styles['img-info']} alt="" />
    } else if (type === ReciboInfoType.EXPIRO_RECIBO) {
      return <img src={policy_expired} className={styles['img-info']} alt="" />
    } else {
      return <img src={info_img} className={styles['img-info']} alt="" />
    }
  }

  const MessageType = () => {
    if (message) {
      return (<p className={styles['pago-recibido-info']}>{message}</p>);
    } else {
      if (type === ReciboInfoType.NO_ENCONTRADA || 
          type === ReciboInfoType.DESHABILITADA || 
          type === ReciboInfoType.INFO ||
          type === ReciboInfoType.MAS_TARDE ||
          type === ReciboInfoType.EXPIRO_RECIBO
        ) {
        return (
          <p className={styles['pago-recibido-info']}>Si tienes problemas para realizar tu pago, comunícate con nosotros al
          <span className={styles['pago-recibido-info-telefono']}> {<WhatsLink />} </span>y con gusto te atenderemos.</p>
        )
      } else if (type === ReciboInfoType.EXPIRO_LIGA || type === ReciboInfoType.YA_PAGADA) {
        return (
          <p className={styles['pago-recibido-info']}>Si necesitas ayuda o tienes dudas, comunícate con nosotros al
          <span className={styles['pago-recibido-info-telefono']}> {<WhatsLink />} </span>y con gusto te atenderemos.</p>
        )
      } else if (type === ReciboInfoType.INACTIVIDAD) {
        return (
          <p className={styles['pago-recibido-info']}>Por favor, vuelve a ingresar al enlace o recarga la página para continuar.</p>
        )
      } else if (type === ReciboInfoType.ERROR_LIGA_REINTENTO) {
        return (
          <button disabled={isActionDisabled} className={styles['boton-action']} onClick={() => onClickAction("retry_error_liga")}>
            Reintentar
          </button>
        )
      } else {
        return (
          <p className={styles['pago-recibido-info']}>Sí necesitas ayuda o tienes dudas, comunícate con nosotros al
          <span className={styles['pago-recibido-info-telefono']}> {<WhatsLink />} </span>y con gusto te atenderemos.</p>
        )
      }
    }
  }

  return (
    <div className={styles['container-info']}>
      <p className={styles['pago-recibido-titulo']}>{title || ""}</p>
      <ImageTypeInfo />
      <MessageType />
    </div>
  );
}
