const PaymentScreens = Object.freeze({
    RESUMEN: Symbol('resumen'),
    REALIZAR_PAGO: Symbol('realizar_pago'),
    PAGO_EXITOSO: Symbol('pago_exitoso'),
    INFO: Symbol('info'),
});

const ReciboInfoType = Object.freeze({
    NO_ENCONTRADA: 1,
    EXPIRO_LIGA: 2,
    DESHABILITADA: 3,
    YA_PAGADA: 4,
    INFO: 5,
    INACTIVIDAD: 6,
    ERROR_LIGA_REINTENTO: 7,
    MAS_TARDE: 8,
    EXPIRO_RECIBO: 9,
});

const ContactPhone = "55 4747 2271";

export { PaymentScreens, ReciboInfoType, ContactPhone };